import React, { useContext } from 'react'
import { Flex } from 'rebass'
import styled, { css } from 'styled-components'
import { addToCart, backBlack } from '../../assets/icons'
import { media } from '../../utils/mediaBreakpoints'
import { Icon } from '../atoms'
import { Text } from '../atoms/typography'
import IconWithNotificationsCount from './IconWithNotificationsCount'
import { isMobileWebPwa } from '../../utils/common'
import MobileWebPwaContext from '../../utils/contexts/MobileWebPwaContext'
import searchProduct from '../../assets/icons/search_product.svg'
import { handleActionKeyPress } from '../../utils/helper/keyboardKeyPress'

export const ExtendedFlex = styled(Flex)`
  position: fixed;
  top: ${props => props.top ? props.top : props.isModal ? '0px' : '60px'};
  width: 100%;
  left: 0;
  z-index: ${props => props.zIndex || 1};
  ${({ borderBottom, theme }) => {
  return borderBottom && css`
      border-bottom: 1px solid ${theme.borders.newBorderColor};
  `;
}}
  ${media.mobile`
    top: ${props => (props.isMobileWeb && props.showMobileWebPwaBanner) ? '68px' : 0}
  `}
`

const Header = ({onGoBack, borderBottom = true, secondTitleClickHandler, renderButton, fontFamily = 'bold', fontSize='lg', text, secondTitle, benefitType, onClickCartView, count, step, searchIconRequired, onClickSearchIcon, mt, isModal, top, ml='ml-4 sm:-ml-14', mlForSecondTitle=['95px', '210px']}) => {
  const [showMobileWebPwaBanner] = useContext(MobileWebPwaContext);

  return (
    <ExtendedFlex backgroundColor={'white'} py='20px' pr={['16px', 0]} borderBottom={borderBottom} justifyContent={'center'} isModal={isModal} top={top} isMobileWeb={isMobileWebPwa()} showMobileWebPwaBanner={showMobileWebPwaBanner}>
      <div className={`flex items-center ${isModal ? `w-full ml-4` : `w-full max-w-screen-sm ${ml}`} ${searchIconRequired ? 'justify-between' : ''}`}>
        <div className='flex justify-between w-full'>
          <div className='flex items-center'>
            <Icon tabIndex="0" onKeyUp={(e) => handleActionKeyPress(e, onGoBack)} alt="Go Back" src={backBlack} size='lg' mb={'2px'} mr='16px' data-testid='back-icon' onClick={onGoBack} pointer />
            <Text tabIndex="0" aria-label={`Page title is ${text}`} color='bannerBackground' fontSize={fontSize} mt={mt} lineHeight='20px' bold={fontFamily === 'bold'} bolder={fontFamily === 'bolder'}>{text}</Text>
          </div>
          {renderButton}
        </div>
        {searchIconRequired && <div className='flex ml-24 size-6'>
          <img src={searchProduct} className='size-6' onClick={onClickSearchIcon} />
        </div>}
      </div>
      {secondTitle && <Text tabIndex={'0'} onKeyUp={(e) => handleActionKeyPress(e, secondTitleClickHandler)} ml={mlForSecondTitle} bold fontSize='sm' color='blue' whiteSpace='nowrap' cursor='pointer' onClick={secondTitleClickHandler}>{secondTitle}</Text>}
      {['ahc', 'dental', 'vision', 'flu_vaccination', 'covid_vaccination', 'nhc'].includes(benefitType) && count > 0 && (step === 1 || step === 2) && <IconWithNotificationsCount tabIndex={0} onKeyUp={(e) => handleActionKeyPress(e, onClickCartView)} onClick={onClickCartView} borderRadius='50%' fontSize={'xxs'} countWidth='17px' iconBackgroundColor={'red'} count={count} src={addToCart} size='lg' />}
    </ExtendedFlex>
  )
}

Header.defaultProps = {
  searchIconRequired: false,
  isModal: false
}

export default Header
