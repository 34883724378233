const primaryColor = '#0445c8';
const secondaryColor = '#6c757d';
const dangerColor = '#dc3545';
const warningColor = '#ffc107';
const infoColor = '#17a2b8';
const successColor = '#28a745';
const lightColor = '#f8f9fa';
const darkColor = '#343a40';
const whiteColor = '#fff';
const darkestBlue = '#0000ff';
const thickGrey = '#CBD5E1'; //slate-300
const darkGreen = '#00A04F'



const btnPrimaryColor = '#0445c8';
const btnDangerColor = 'red';
const btnDefaultColor = '#d3dbe6';
const btnSuccessColor = '#33c133';
const btnWhiteColor = 'white';
const extraInformation = '#334155'; //slate-700
const btnLighterSkyBlue = '#F3F7FF'
const btnPinkColor = '#FEF1F1'
const btnLightBlue = '#475569' //slate-600
const btnBorderColor = '#E5E8F0'
const btnLightBlueBackgroundColor = '#EFF6FF'
const btnThickBlue = '#0445c8'
const btnLightGreenColor = '#EFFCF5'
const lightestRedNew = '#FEF1F1'

export default {
  breakpoints: ['600px', '900px', '1200px', '1800px'],
  space: [0, 1, 2, 4, 8, 16, 32, 64, 128, 256, 512], // in px
  fonts: {
    normal: 'Figtree-Medium',
    semiBold: 'Figtree-SemiBold',
    extraBold: 'Figtree-Bold'
  },
  fontSizes: {
    xxs: '10px',
    xs: '12px',
    xsl: '13px',
    sm: '14px',
    sml: '15px',
    md: '16px',
    mdL: '18px',
    lg: '20px',
    lgL: '22px',
    xl: '24px',
    xxl: '30px',
    xxxl: '36px',
    mxL: '42px',
    vxl: '44px'
  },
  iconSizes: {
    xxs: '5px',
    xs: '10px',
    s: '12px',
    sm: '15px',
    smm: '14px',
    ssm: '16px',
    smL: '18px',
    md: '20px',
    lg: '24px',
    mdL: '28px',
    xl: '30px',
    xlmd: '32px',
    xxl: '35px',
    xxsl: '36px',
    xxml: '38px',
    sxL: '40px',
    xxxl: '44px',
    xxxml: '48px',
    xxxvl: '52px',
    xxxxl: '55px',
    smxl: '60px',
    mxl: '66px',
    mxxl: '70px',
    vxl: '100px',
    vl: '80px'
  },
  lineHeights: {
    xs: '0.5em',
    xxs: '0.75em',
    sm: '1em',
    smm: '1.25em',
    md: '1.5em',
    lg: '2em',
    xl: '2.5em',
    xxl: '3.0em',
  },
  letterSpaces: {
    xs: '0.001em',
    sm: '0.005em',
    md: '0.01em',
    lg: '0.05em',
    lgS: '0.06em',
    lgM: '0.075em',
    xl: '0.08em',
    xxl: '0.1em',
  },
  borders: {
    default: '#eaf1ff', //blue-50
    input: '#ced4da',
    lighter: '#e8f0f9',
    secondary: secondaryColor,
    primary: primaryColor,
    white: '#fffff',
    borderGrey: '#E5E8F0',
    darkGreyNew: thickGrey,
    darkerRed: '#c80010',
    lightGrey: '#475569',
    newBorderColor:'#E2E8F0'

  },
  radii: [0, 2, 4, 8, 16],
  opacity: [0, 0.2, 0.4, 0.6, 0.8, 1],
  shadows: {
    sm: '0 0 4px rgba(0, 0, 0, 0.125)',
    lg: '0 0 24px rgba(0, 0, 0, 0.125)'
  },
  toasts: {
    'success': {
      color: whiteColor,
      backgroundColor: successColor
    },
    'error': {
      color: whiteColor,
      backgroundColor: dangerColor
    },
    'warning': {
      color: whiteColor,
      backgroundColor: warningColor
    },
    default: {
      color: whiteColor,
      backgroundColor: darkColor
    }
  },
  stickers: {
    background: {
      brown: '#fffaee',
      green: "#e0ffee",
      red: "#fff1f1"
    },
    brown: '#70411b',
    green: '#1B7042',
    red: '#bf3030'
  },
  links: {
    'primary': {
      color: primaryColor,
      hoverColor: primaryColor,
      disabledColor: whiteColor
    },
    'secondary': {
      color: secondaryColor,
      hoverColor: primaryColor,
      disabledColor: secondaryColor
    },
    'default': {
      color: secondaryColor,
      hoverColor: primaryColor,
      disabledColor: secondaryColor
    },
    'success': {
      color: primaryColor,
      hoverColor: secondaryColor,
      disabledColor: secondaryColor
    },
    'danger': {
      color: btnDangerColor,
      hoverColor: btnDangerColor,
      disabledColor: secondaryColor
    },
    'warning': {
      color: warningColor,
      hoverColor: secondaryColor,
      disabledColor: secondaryColor
    },
    'disabled': {
      color: secondaryColor,
      hoverColor: secondaryColor,
      disabledColor: secondaryColor
    },
    'stale': {
      color: extraInformation,
      hoverColor: extraInformation,
      disabledColor: extraInformation
    }
  },
  buttons: {
    'stateless': {
      color: btnWhiteColor,
      backgroundColor: thickGrey,
      borderColor: thickGrey,
      hoverBgColor: thickGrey,
      hoverColor: btnWhiteColor,
      hoverBrColor: thickGrey
    },
    'default': {
      color: btnWhiteColor,
      backgroundColor: btnDefaultColor,
      borderColor: btnDefaultColor,
      hoverBgColor: btnDefaultColor,
      hoverColor: btnWhiteColor,
      hoverBrColor: btnDefaultColor
    },
    'light': {
      color: whiteColor,
      backgroundColor: lightColor,
      borderColor: btnDefaultColor,
      hoverBgColor: btnDefaultColor,
      hoverColor: btnWhiteColor,
      hoverBrColor: btnDefaultColor
    },
    'plain': {
      color: darkColor,
      backgroundColor: lightColor,
      borderColor: btnDefaultColor,
      hoverBgColor: lightColor,
      hoverColor: darkColor,
      hoverBrColor: btnDefaultColor
    },
    'primary': {
      color: btnWhiteColor,
      backgroundColor: btnPrimaryColor,
    },
    'secondary': {
      color: btnWhiteColor,
      backgroundColor: secondaryColor,
      borderColor: secondaryColor,
      hoverBgColor: secondaryColor,
      hoverColor: btnWhiteColor,
      hoverBrColor: secondaryColor
    },
    'success': {
      color: btnWhiteColor,
      backgroundColor: btnSuccessColor,
      borderColor: btnSuccessColor,
      hoverBgColor: btnSuccessColor,
      hoverColor: btnWhiteColor,
      hoverBrColor: btnSuccessColor
    },
    'danger': {
      color: btnWhiteColor,
      backgroundColor: btnDangerColor,
      borderColor: btnDangerColor,
      hoverBgColor: btnDangerColor,
      hoverColor: btnWhiteColor,
      hoverBrColor: btnDangerColor
    },
    'primary-outline': {
      backgroundColor: btnLightBlueBackgroundColor,
      color: btnPrimaryColor,
      borderColor: btnPrimaryColor,
      hoverBgColor: btnPrimaryColor,
      hoverColor: btnWhiteColor,
      hoverBrColor: btnPrimaryColor
    },
    'secondary-outline': {
      backgroundColor: btnWhiteColor,
      color: secondaryColor,
      borderColor: secondaryColor,
      hoverBgColor: secondaryColor,
      hoverColor: btnWhiteColor,
      hoverBrColor: btnPrimaryColor
    },
    'danger-outline': {
      backgroundColor: lightestRedNew,
      color: btnDangerColor,
      borderColor: btnDangerColor,
      hoverBgColor: btnDangerColor,
      hoverColor: btnWhiteColor,
      hoverBrColor: btnDangerColor
    },
    'success-outline': {
      backgroundColor: btnWhiteColor,
      color: btnSuccessColor,
      borderColor: btnSuccessColor,
      hoverBgColor: btnSuccessColor,
      hoverColor: btnWhiteColor,
      hoverBrColor: btnSuccessColor
    },
    'default-outline': {
      backgroundColor: btnWhiteColor,
      color: btnPrimaryColor,
      borderColor: btnDefaultColor,
      hoverBgColor: btnPrimaryColor,
      hoverColor: btnWhiteColor,
      hoverBrColor: btnPrimaryColor
    },
    'plain-outline': {
      color: btnDefaultColor,
      hoverBgColor: 'none',
      hoverColor: btnWhiteColor,
      hoverBrColor: btnPrimaryColor,
      hoverBrStyle: 'solid'
    },
    'transparent-outline': {
      color: extraInformation,
      borderColor: extraInformation,
      backgroundColor: 'transparent',
      hoverBgColor: 'none',
      borderRadius: '3px'
    },
    'lighterSkyBlue-outline': {
      color: btnPrimaryColor,
      backgroundColor: btnLighterSkyBlue,
      borderColor: btnLighterSkyBlue,
      hoverBgColor: 'none',
      hoverColor: 'none',
      hoverBrColor: btnPrimaryColor
    },
    'danger-pink': {
      color: btnDangerColor,
      backgroundColor: btnPinkColor,
      borderColor: btnLighterSkyBlue,
      hoverBgColor: 'none',
      hoverColor: 'none',
      hoverBrColor: btnDangerColor
    },
    'tag-button': {
      color: btnLightBlue,
      backgroundColor: btnWhiteColor,
      borderColor: btnBorderColor,
      hoverBgColor: btnPrimaryColor,
      hoverColor: btnWhiteColor,
    },
    'healthRiskInsights-button': {
      backgroundColor: btnLightBlueBackgroundColor,
      color: btnThickBlue,
      borderColor: btnLightBlueBackgroundColor,
      hoverBgColor: btnPrimaryColor,
      hoverColor: btnWhiteColor,
      hoverBrColor: btnPrimaryColor,
    },
    'whiteGrey': {
      backgroundColor: whiteColor,
      color: btnThickBlue,
      hoverBgColor: btnPrimaryColor,
      hoverColor: btnWhiteColor,
      borderColor: thickGrey,
    },
    'grayDisabled': {
      backgroundColor: btnLightBlueBackgroundColor,
      color: thickGrey,
      borderColor: btnLightBlueBackgroundColor,
      hoverBgColor: btnPrimaryColor,
      hoverColor: thickGrey
    },
    'disabled': {
      backgroundColor: thickGrey,
      borderColor: 'transparent',
      color: '#FFFFFF',
    },
    'safe-green': {
      color: darkGreen,
      backgroundColor: btnLightGreenColor,
      borderColor: darkGreen,
      hoverBgColor: 'none',
      hoverColor: 'none',
      hoverBrColor: darkGreen
    },

  },
  cards: {
    primary: {
      color: '#fff',
      backgroundColor: primaryColor
    },
    secondary: {
      color: '#fff',
      backgroundColor: secondaryColor
    },
    success: {
      color: '#fff',
      backgroundColor: successColor
    },
    danger: {
      color: '#fff',
      backgroundColor: dangerColor
    },
    info: {
      color: '#fff',
      backgroundColor: infoColor
    },
    outline: {
      color: primaryColor,
      backgroundColor: 'transparent',
      boxShadow: 'inset 0 0 0 2px'
    }
  },
  tags: {
    backgroundColor: '#e9f1ff',
    green: {
      color: "#1B7042",
      backgroundColor: "#E5F8EE"
    },
    grey: {
      color: "#57616F",
      backgroundColor: "#F3F6FA"
    },
    yellow: {
      color: "#70411B",
      backgroundColor: "#FFFAEE"
    },
    red: {
      color: "#C80010",
      backgroundColor: "#FEF0F0"
    },
    brown: {
      color: "white",
      backgroundColor: "rosybrown"
    },
    newYellow: {
      color: '#70411B',
      backgroundColor: '#FFEEC0'
    }
  },
  alerts: {
    success: '#c4f1d8',
    danger: '#feeeee',
    warning: '',
  },
  colors: {
    bodyHoverColor: '#f8f9fa',
    borderColor: '#e5e9f0', // gray-200
    borderColorNew: '#F2F3F7',
    blue: "#2168f3", //blue-600
    darkBlue: '#2154f3',
    darkerBlue: '#2b56db',
    darkestBlue: darkestBlue,
    lightBlue: '#475569', //slate-600
    lighterBlue: '#CCDBF6',
    lightestBlue: '#f4f6fb',
    skyBlue: '#8DB0F6',
    green: "#3FCB7F",
    darkGreen: '#00A04F', //green-400
    darkerGreen: '#004A30',
    darkestGreen: '#1B7042',
    lightGreen: '#c4f1d8',
    lighterGreen: '#dbfbe9',
    lightestGreen: '#E5F8EE',
    lightestGreenNew: '#D8F6E6',
    mediumDarkGreen: '#00A04F',
    yellow: "#ffc72d",
    darkYellow: '#ffb500',
    darkerYellow: '#916c00',
    darkestYellow: '#70411b',
    lightYellow: '#ca9b59',
    lighterYellow: '',
    lightestYellow: '#fff8e6', // orange-50
    red: "#ff6969",
    darkRed: '#d74651',
    darkerRed: '#c80010',
    darkestRed: '#bf3030',
    lightRed: '#ffe4e4',
    lighterRed: '#feeeee',
    lightestRed: '#fff3f3',
    lightestRedNew: '#FEF1F1',
    mediumRed: '#EA5757',
    mediumDarkRed: '#D74651',
    border: '#eaf1ff', //blue-50
    body: '#555555',
    title: '#0F172A',
    subtitle: '#475569', //slate-600
    lightestGrey: '#f4f4f4',
    lighterShadeGrey: '#f3f5f8',
    lighterGrey: '#e8e8e8',
    lightGrey: '#475569', //slate-600
    bluerGrey: '#c7cde1',
    grey: '#475569', //slate-500
    grayNew: '#475569', //slate-600
    darkGrey: '#757575',
    darkerGrey: '#555555',
    darkestGrey: '#333333',
    footerGrey: '#fafafa',
    darkIndigo: '#5163ba',
    primary: primaryColor, // Do not change the property name as its values are dynamically used in code. And it's values are not found with a search.
    secondary: secondaryColor, // Do not change the property name as its values are dynamically used in code. And it's values are not found with a search.
    success: successColor, // Do not change the property name as its values are dynamically used in code. And it's values are not found with a search.
    danger: dangerColor, // Do not change the property name as its values are dynamically used in code. And it's values are not found with a search.
    error: dangerColor, // This color is same as dangerColor as it's context is different in input and buttons. Do not change the property name as its values are dynamically used in code. And it's values are not found with a search.
    warning: warningColor, // Do not change the property name as its values are dynamically used in code. And it's values are not found with a search.
    info: infoColor,
    light: lightColor,
    dark: darkColor,
    white: whiteColor,
    textSuccessColor: '#24d2b5',
    dashboardBannerColor: '#001133',
    dashboardAnnouncementColor: '#fffaee',
    infoCard: '#ddf2ff',
    headingColor: '#0F172A',
    extraInformation: '#334155', //gray-700
    backgroundColor: '#f8fafc', //gray-100
    bannerBackground: '#1E293B', //gray-800
    loginBackground: '#123087',
    backgroundGrey: '#fafbfd',
    alertYellow: '#ff9800',
    darkestGreyNew: '#475569', // slate-500
    lightestGreyNew: '#f8fafc', //gray-100
    borderGrey: '#E5E8F0',
    newBorderColor: '#E2E8F0',
    borderLightGrey: "#d3dbe6",
    mediumGrey: '#F2F2F2',
    lightstGreNew: '#f8fafc', //gray-100
    darkGreyNew: thickGrey,
    mediumGreen: '#2ab268',
    lightBrown: '#fffaee',
    thickBrown: '#70411b',
    lighterBlueGrey: '#e5eeff', //blue-100
    disabledText: '#94A3B8',// slate-400
    goldColor: '#A86500',
    lightOrange: '#EF8E59',
    thickGrey: thickGrey,
    newLightestBlue: '#bad0fc',
    newLightestGrey: '#EFF6FF', //blue-50
    newLighterGrey: '#e3eaff',
    heavySurge: '#fef6ed',
    lightCyan: '#E7F6F8',
    paleRed: "#FCA9A9",
    alertInformation: "#46330A",
    aliceBlue: "#f6f7f9", //gray-100
    operatorColor: '#444',
    moderateGreen: '#EBFFF4',
    dimGreenBorder: '#B1E8CA',
    moderateRed: '#FFF1F1',
    cornflowerBlue: '#5A8BED',
    extraDarkBlue: '#0445C8',
    btnLighterSkyBlue: '#f3f7ff',
    cardLightBlueBackground: '#EFF6FF',
    darkerGreyNew: '#4C4C4C',
    lightBlueNew: '#BFDEF3',
    pictonBlue: '#03318C',
    mediumDarkGrey: '#DCE0ED',
    darkOrange: '#E68902',
    mediumDarkOrange: '#FFE4BB',
    lighterOrange: '#FFFCF3',
    thickBlue: '#0445c8', //blue-700
    thickYellow: '#C2801D',
    yellowBanner: '#FEFBED',
    goldYellow: '#FFE3B5',
    lightGold: '#FAF6F2',
    thickBlack: '#243353',
    lightSkyBlue: '#475569',
    navyBlue: '#334155', //gray-700
    slateBlue: '#475569',
    lightYellowBorder: '#F4E3C3',
    disabledGrey: '#C4C4C4',
    blueGrey: '#C5D6F5',
    heartRed: '#FEF0F0',
    lightYellowHeart: '#FFF7E3',
    darkCyanBlue: '#003A67',
    ghostWhite: '#EEF1F7',
    thickOrange: '#EFA943',
    lightWhite: '#FFFFFF',
    lightGreenColor: '#EFFCF5',
    mediumBrown: '#C87800',
    dilutedBlue: '#BDDDF3',
    sienna: '#A06918',
    lightestOrange: '#EDDBDE',
    goldenColor: '#F19920',
    darkGreenText: '#008858', //green-700
    extraYellow: '#EBA700',
    mandyRed: '#C54B4F',
    iris: '#615BBB',
    lightestGreyBorder: '#ced4da',
    catskillWhite: '#EEF6FF',
    mintGreen: '#F1FCF7',
    royalBlue: '#286ADB',
    steelBlue: '#475569' //slate-600
  }
};
