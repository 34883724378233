import { SET_PRESCRIPTION_DATA, ADD_TO_CART, ITEMS_ADD_REMOVE, SET_CURRENT_ADDRESS, SET_CATEGORY_DATA, TOGGLE_ADDRESS_MODAL, UPDATE_MOBILE_NUMBER,
  TOGGLE_LOADING, CLEAN_UP_CART, TOGGLE_REMINDER, SHOW_ALERT_MODAL, ADD_PRESCRIPTIONS, PAST_PRESCRIPTIONS, UPDATE_GLOBAL_CART, UPDATE_CART_LOADING, SHOW_PRODUCT_UPDATE_STATE, ADD_SUBSCRIPTION_TO_CART, REMOVE_SUBSCRIPTION_FROM_CART, SHOW_VALUE_PLUS_CART_MODAL, UPDATE_CITY_DATA, UPDATE_SUGGESTED_COUPON } from '../../constants/pharmacy.constants'
import HomeAPI from '../home.api';


const pharmacyReducer = (state, action) => {
  const { type, ...data } = action;
  switch(type) {
  case UPDATE_CART_LOADING:
    return {...state, isCartLoading: data.isCartLoading}
  case ADD_TO_CART: {
    if(data.reOrder){
      return {...state, cart: [...state.cart, {...data.cart}]}
    }

    return {...state, cart: [...state.cart, {...data.cart, quantity: data?.cart.min_limit_per_order || 1}], skipPrescription: false}
  }
  case ITEMS_ADD_REMOVE: {
    let newState = data.cart || state.cart
    let product = state.cart.findIndex((item) => item.id === data.productId)

    if(product != -1){
      newState[product].quantity = data.itemsAdded
    }
    if(data.itemsAdded == 0){
      let newCart = newState.filter((product) => product.id != data.productId)
      return {...state, cart: newCart, prescriptionsReviewData: {} }
    }
    return {...state, cart: newState}
  }
  case SET_CURRENT_ADDRESS: {
    let addressId = data.currentAddress?.id
    if ( !data.globalCartUpdate ) {
      const planId = window.localStorage.getItem('planId')
      {addressId && HomeAPI.updatePharmacyCart({ planId: planId, data : {address_id: addressId, action_type: 'ADDRESS_ADD'}}, (res) => {
        if(res.data.success) {
          data.successCallback()
        }
      }, () => {})}
    }
    return {...state, currentAddress: data.currentAddress, serviceable: data.serviceable}
  }

  case SET_CATEGORY_DATA: {
    return {...state, currentCategoryData: data.categoryList}
  }

  case TOGGLE_ADDRESS_MODAL: {
    return {...state, showAddressModal: data.showAddressModal}
  }

  case TOGGLE_LOADING: {
    return {...state, loading: data.status}
  }

  case SHOW_PRODUCT_UPDATE_STATE: {
    return {...state, productLoader: data.productLoader}
  }

  case TOGGLE_REMINDER : {
    return {...state, reminder: data.status}
  }

  case UPDATE_MOBILE_NUMBER: {
    return {...state, mobileNumber: data.mobileNumber}
  }

  case SET_PRESCRIPTION_DATA: {
    return {...state, prescriptionsReviewData: data.reviewData, skipPrescription: data.skipPrescription}
  }

  case CLEAN_UP_CART: {
    return {...state, prescriptionsReviewData: {}, cart: [], cartSummary: {}, selectedPrescriptions:  [], pastPrescriptions: [] }
  }

  case SHOW_ALERT_MODAL: {
    return {...state, openAlertModal : data.open }
  }
  
  case ADD_PRESCRIPTIONS: {
    return {...state, selectedPrescriptions: data.selectedPrescriptions, isPrescriptionRemove: false}
  }

  case PAST_PRESCRIPTIONS: {
    return {...state, pastPrescriptions: data.pastPrescriptions}
  }

  case UPDATE_GLOBAL_CART: {
    return {...state, cart: data.cart, currentAddress: data.currentAddress, skipPrescription: data.skipPrescription, globalCartUpdate: data.globalCartUpdate, cityName: data.cityName, zipCode: data.zipCode, suggestedCoupon: data.suggestedCoupon}
  }

  case ADD_SUBSCRIPTION_TO_CART: {
    return {...state, subscriptionId: data.subscriptionId }
  }

  case REMOVE_SUBSCRIPTION_FROM_CART: {
    return {...state, subscriptionId: null, showValuePlusCartModal: false }
  }

  case SHOW_VALUE_PLUS_CART_MODAL: {
    return {...state, showValuePlusCartModal: data.showValuePlusCartModal }
  }

  case UPDATE_CITY_DATA: {
    return {...state, serviceable: data.serviceable, cityName: data.cityName, zipCode: data.zipCode}
  }

  case UPDATE_SUGGESTED_COUPON: {
    return {...state, suggestedCoupon: data.suggestedCoupon}
  }

  default: {
    return state
  }
  }
}

export default pharmacyReducer