
import React, { useState, Fragment, useCallback, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import Portals from '../../utils/portals';
import { Flex } from 'rebass';
import { Motion, spring } from 'react-motion'
import { media, excludeMedia } from '../../utils/mediaBreakpoints';
import { close } from '../../assets/icons';
import { Icon } from '../atoms';
import { animationDefaultConfig } from '../../utils/common';

const ModalContainer = styled(Flex).attrs(({ size = 'xs' }) => {
  const config = {
    margin: '2rem auto'
  }
  if(size === 'xxs') {
    config.maxWidth = {
      desktop: '35vw',
      tablet: '50vw',
      mobile: '100vw'
    }
  }
  else if(size === 'xs') {
    config.maxWidth = {
      desktop: '50vw',
      tablet: '70vw',
      mobile: '100vw'
    }
  } else if(size === 'sm') {
    config.maxWidth = {
      desktop: '60vw',
      tablet: '75vw',
      mobile: '100vw',
    }
  } else if(size === 'md') {
    config.maxWidth = {
      desktop: '70vw',
      tablet: '80vw',
      mobile: '100vw',
    }
  } else if(size === 'lg') {
    config.maxWidth = {
      desktop: '80vw',
      tablet: '85vw',
      mobile: '100vw',
    }
  } else if(size === 'xl') {
    config.maxWidth = {
      desktop: '90vw',
      tablet: '90vw',
      mobile: '100vw',
    }
  } else if(size === 'xxl') {
    config.maxWidth = {
      desktop: '100vw',
      tablet: '100vw',
      mobile: '100vw',
    }
  }
  return {
    ...config
  }
})`
    background-color:${props => ({theme: {colors}}) => colors[props.backgroundColor]};
    position: fixed;
    top: ${props => props.top || '5%'};
    left: ${props => props.left};
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    overflow: visible;
    text-align: ${props => props.textAlign || 'center'};
    z-index: ${props => props.zIndex || '50'};
    ${({ margin }) => css`
        margin: ${margin};
    `}
    ${media.mobile`${({ direction }) => {
  if(direction === 'top-bottom') {
    return css`
          top: 0%;
        `
  } else if (direction === 'bottom-top') {
    return css`
        top: unset;
        bottom: 0%;
      `
  } else if (direction === 'left-right') {
    return css`
      top: unset;
      bottom: unset;
      left: 0%;
      right: unset;
      padding: unset;
    `;
  } else if (direction === 'right-left') {
    return css`
      top: unset;
      bottom: unset;
      right: 0%;
      left: unset;
      padding: unset;
    `;
  }else {
    return css`
          top: ${props => props.top || 'unset'};
          bottom: 0%;
          padding: unset;
        `
  }
}}
        ${props => props.mobileTop && css`top: ${props.mobileTop}`};
        padding-left: ${props => props.pl};
        padding-right: ${props => props.pr};
        margin: 0;
        width: ${({ maxWidth: {mobile}}) => mobile };
        min-height: ${({ minHeight: { mobile }}) => mobile };
    `}
    ${media.tablet`
        width: ${({ maxWidth: {tablet}}) => tablet };
        min-height: ${({ minHeight: { tablet }}) => tablet || '50vh'};
    `}
    ${media.desktop`
        width: ${({ maxWidth: {desktop}}) => desktop };
        display: ${props => props.webDisplay};
        ${({minHeight}) => minHeight.desktop && css`
          min-height: ${({ minHeight }) => minHeight};
        `}
    `}
`

const ModalOverlay = styled(Flex)`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${({borderRadius}) => borderRadius && css`border-radius: ${borderRadius}`}
    background-color: ${({backgroundColor})=> backgroundColor || 'rgba(0, 0, 0, 0.65)' };
    z-index: ${props => props.overlayZIndex || '50'};
    display: ${props => props.webDisplay || props.tabDisplay};
`

// Close modal icon won't be visible for tablet and desktop views.
// User can click out of the modal view area. And the modal will close.
const ModalCloseTag = styled(Icon).attrs(() => ({ size: 'sm' }))`
    position: absolute;
    top: ${({closeTagProps}) => closeTagProps?.top || '10px' };
    right: ${({closeTagProps}) => closeTagProps?.right || '10px' };
    z-index: 1;
    cursor: pointer;
    ${excludeMedia.mobile`
      ${({ showIcon }) => showIcon === false && `display: none;`}
    `}
`

/**
 * To respect the react-motion transitions. use onClose method from here to close the modal.
 * This will add fade in effect when the modal is closed. Modal is closed after a timeout of 1000ms With fade out transition. 
 * NOTE: only call onClose function from here to close the modal as it gives the react-motion effects.
 * showIcon: when set to true, shows close/cancel icon on all viewports. expected behaviour to hide it on desktop viewport.
 * closeOnClickOverlay: default set to true, on set to true allows user to close modal on user click out of the modal.
 */
const Modal = ({
  children, closeModal = () => {}, closeIcon, backgroundColor,springAnimation, modalCloseTagProps,borderRadius,
  direction, visibleOn, closeOnClickOverlay, overflow, overlayBackgroundColor, type="modal", // avoids closing modal on clicking overlay area. handling cases when a save, edit of modal happens.
  ...props
}) => {
  const initialPosition = direction === 'top-bottom' ? -100 : 100
  const lastFocusedElement = useRef(null);
  const modalRef = useRef(null);

  const willLeave = () => { 
    return springAnimation ? {y: spring(0, animationDefaultConfig), opacity: spring(1, animationDefaultConfig)} : {y: spring(0), opacity: spring(1)}
  }

  const [presentStyle, updatePresentStyle] = useState(willLeave())

  const onUnmount = () => ( springAnimation ? {y: spring(initialPosition, springAnimation), opacity: spring(0, springAnimation)} :
    {y: spring(initialPosition), opacity: spring(0)})

  const handleClose = useCallback(() => {
    closeModal();
    updatePresentStyle(onUnmount());
    if (lastFocusedElement.current && typeof lastFocusedElement.current.focus === "function") {
      lastFocusedElement.current.focus();
    }
  }, [closeModal]);
  
  useEffect(() => {
    lastFocusedElement.current = document.activeElement;
    if (modalRef.current) {
      modalRef.current.focus();
    }
  }, []);
  
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        handleClose();
      }
    };
    
    document.addEventListener("keydown", handleKeyDown);
  
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleClose]);

  return (
    <Portals type={type} visibleOn={visibleOn}>
      <ModalOverlay backgroundColor={overlayBackgroundColor} onClick={closeOnClickOverlay ? handleClose : null} borderRadius={borderRadius} overlayZIndex={props.overlayZIndex} />
      <Motion
        defaultStyle={{y: initialPosition, opacity: 0}}
        style={presentStyle}
        willLeave={willLeave}>
        {(style) => (<ModalContainer tabIndex={-1} ref={modalRef} role='dialog' aria-modal='true' direction={direction} left={props.left} backgroundColor={backgroundColor} textAlign={props.textAlign} style={
          {
            opacity: style.opacity,
            transform: `translate(-50%, ${style.y}px)`,
            WebkitTransform: `translate(-50%, ${style.y}px)`,
            overflowY: overflow,
            outline: 'none'
          }
        } {...props}>
          <Fragment>
            {closeIcon && <ModalCloseTag onClick={handleClose} src={close} closeTagProps={modalCloseTagProps}/>}
            {children}
          </Fragment></ModalContainer>
        )}
      </Motion>
    </Portals>)
}

Modal.defaultProps = {
  closeIcon: false,
  closeOnClickOverlay: true,
  minHeight: {},
  direction: 'top-bottom',
  backgroundColor: 'inherit',
  springAnimation: false,
  overflow: 'inherit',
  left: '50%'
}

export default Modal;