import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import { media } from '../../utils/mediaBreakpoints';

// TODO:: Animation while changing the percentage.
const ProgressbarContainer = styled(Flex)`
  width: 100%;
  background-color: ${({ theme: { colors }}) => colors.borderGrey};
  border-radius: 4px;
  height: 8px;
  ${media.mobile`
    margin-left: -5px;
    margin-right: -5px;
  `}
`

const ProgressbarComplete = styled(Box)`
  border-radius: 4px;
  background-color: ${({ theme: { colors }, backgroundColor }) => backgroundColor || colors.green};
`

const ProgressbarInComplete = styled(Box)`
  width: 0;
`

const Progressbar = ({ percent, barColor }) => (
  <ProgressbarContainer>
    <ProgressbarComplete className='animated-bar' width={`${percent}%`} backgroundColor={barColor}/>
    <ProgressbarInComplete/>
  </ProgressbarContainer>
)

export default Progressbar;