import React, { lazy } from 'react'
import { Text } from '../../atoms/typography'
import SpinnerBlueLoader from '../../../assets/animations/spinner-blue-loader.json'
const LottieContainer = lazy(() => import('../../atoms/LottieContainer'))

const ReviewDetailPageLoader = ({ SpinnerRequired = true, textFontWeight = 'bold', textFontSize='md', text, subText, textColor='lightBlue', backgroundOpacity, position, height, backgroundColor}) => {
  return (
    <div className={`flex top-0 left-0 right-0 bottom-0 w-full z-50 ${position ? position : 'fixed'} ${backgroundOpacity ? backgroundOpacity : 'opacity-90'} ${backgroundColor ? backgroundColor : 'bg-slate-50'} ${height ? height : 'h-full'} items-center`}>
      <div className='flex flex-col w-full items-center'>
        {SpinnerRequired && <LottieContainer animationData={SpinnerBlueLoader} width={'100px'} height={'100px'}/>}
        <div className='flex flex-col sm:flex-row items-center'>
          {text && <Text color={textColor} fontSize={textFontSize} lineHeight='22px' bold={textFontWeight === 'bold'} bolder={textFontWeight === 'bolder'}>{text}&nbsp;</Text>}
          {subText && <Text color={'lightBlue'} fontSize={'md'} lineHeight='22px' bold>{subText}</Text>}
        </div>
      </div>
    </div>
  )
}

export default ReviewDetailPageLoader;