import React, { useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import { Radio, Icon } from "../atoms";
import { slideButton } from "../../assets/icons";
import { Flex } from 'rebass';
import { media, excludeMedia } from '../../utils/mediaBreakpoints';

const IconExtended = styled(Icon)`
  cursor: pointer;
  background-color: transparent;
  position: absolute;
  z-index: 1;
  ${({ left }) => left && css`
    left: ${left};
  `}
  ${({ right }) => right && css`
    right: ${right};
  `}
`

const RadioGroup = styled.div`
  display: flex;
  overflow: auto;
  gap: ${({gap}) => gap};
  padding: ${({p}) => p};
  ${({justifyContent}) => justifyContent && css`justify-content: ${justifyContent}`}
  width: ${props => props.enableLeftArrow && props.enableRightArrow ? '80%' : (props.enableRightArrow || props.enableLeftArrow) ? props.width : '100%'};
  position: relative;
  ${excludeMedia.mobile`
    width: 100%;
    ${({props})=> props.horizantalScroll == false && css`
    overflow: visible;
    flex-wrap: wrap;
  `}
  `}
  ${media.mobile`
    width: 100vw;
    ${({flexWrap}) => flexWrap && css`flex-wrap: ${flexWrap}`}
  `}
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FlexContainer = styled(Flex)`
  position: relative;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  ${({borderRequired}) => borderRequired && css`
    border-bottom: 1px solid #f8fafc;
  `}

`

const HorizontalRadioBtnGroup = ({
  onChange,
  list,
  initialScroll = 0,
  selectedIndex = undefined,
  autoScroll = false,
  className,
  directionRightIcon,
  showDirectionsArrows,
  iconSize,
  minWidth,
  minHeight,
  mr,
  borderColor,
  width,
  padding,
  testid,
  itemTitle,
  justifyContent,
  p,
  gap,
  mb,
  br,
  iconLeft = "-10px",
  parentPadding,
  borderRequired,
  parentMargin, ...props
}) => {
  const scrollElem = useRef(null)
  const [scrollable, updateScrollable] = useState(false);
  const radioList = useState(list.map(item => ({ ...item, ref: React.createRef() })))[0]
  const [enableLeftArrow, updateLeftArrow] = useState(false);
  const [enableRightArrow, updateRightArrow] = useState(true);
  
  useEffect(() => {
    window.addEventListener("resize", updateScrollableState);
    scrollElem.current.scrollLeft = initialScroll.left;
    updateScrollableState();
    autoScroll && autoScrollToActiveItem();
    return () => {
      window.removeEventListener("resize", updateScrollableState);
    };
  }, []);

  const autoScrollToActiveItem = () => {
    let elementIdx = radioList.findIndex(el => el.available)
    if(selectedIndex !== undefined && radioList[selectedIndex]) {
      radioList[selectedIndex].ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    } else if(radioList[elementIdx]) {
      radioList[elementIdx].ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      onChange(elementIdx)
    }
  }

  const updateScrollableState = () => {
    updateScrollable(isScrollable());
  };

  const scrollToLeft = () => {
    scrollElem.current.scrollLeft -= 150;
    if (scrollElem.current.scrollLeft === 0) {
      updateLeftArrow(false);
    } else {
      updateLeftArrow(true);
    }
    updateRightArrow(true);
  };

  const scrollToRight = () => {
    let pScroll = scrollElem.current.scrollLeft;
    scrollElem.current.scrollLeft += 150;
    if (
      parseInt(scrollElem.current.scrollLeft) >=
      parseInt(pScroll) - 50
    ) {
      updateRightArrow(false);
    } else {
      updateRightArrow(true);
    }
    updateLeftArrow(true);
  };

  const setScrollerpoints = () => {
    var maxScrollLeft = scrollElem.current.scrollWidth - scrollElem.current.clientWidth;
    if (scrollElem.current.scrollLeft === 0) {
      updateLeftArrow(false);
    } else if (
      scrollElem.current.scrollLeft === maxScrollLeft
    ) {
      updateRightArrow(false);
    } else {
      updateLeftArrow(true);
      updateRightArrow(true);
    }
  };

  const isScrollable = () => {
    var element = scrollElem.current
    return element && element.scrollWidth > element.clientWidth;
  };

  return (
    <FlexContainer borderRequired={borderRequired} m={parentMargin} className={className} p={parentPadding}>
      {showDirectionsArrows && scrollable && enableLeftArrow && <IconExtended size={iconSize} src={directionRightIcon} left={iconLeft} rotate='180' onClick={scrollToLeft}/>}
      <RadioGroup ref={scrollElem} props={props} enableLeftArrow={enableLeftArrow} enableRightArrow={enableRightArrow} onScroll={setScrollerpoints} justifyContent={justifyContent} p={p} gap={gap} flexWrap={props.flexWrap}>
        {(autoScroll ? radioList : list).map((item, index) => {
          if(item.component) return item.component;
          if(item.title === null) return;
          return (
            <Radio
              key={item.id ?? index}
              onChange={()=>onChange(index)}
              checked={index === selectedIndex}
              borderColor={borderColor}
              minWidth={minWidth}
              minHeight={minHeight}
              mr={mr}
              width={width}
              padding={padding}
              testid={`${testid}-${index}`}
              br={br}
              defaultBgColor={props.defaultBgColor}
              fontSize={props.fontSize}
              mb={mb}
              itemRef={item.ref}
            >
              { typeof(item[itemTitle]) === 'function' ? item[itemTitle](selectedIndex) : item[itemTitle] }
            </Radio>
          )
        })}
      </RadioGroup>
      {showDirectionsArrows && props.horizantalScroll && scrollable && enableRightArrow && <IconExtended size={iconSize} right={iconLeft} src={directionRightIcon} onClick={scrollToRight}/>}
    </FlexContainer>
  );
};

HorizontalRadioBtnGroup.defaultProps = {
  minWidth: '75px',
  mr: '5px',
  borderColor: 'lightGrey',
  width: '90%',
  padding: '9px 7px',
  directionRightIcon: slideButton,
  iconSize: 'xl',
  testid: 'radio',
  borderRequired: false,
  showDirectionsArrows: true,
  horizantalScroll: true,
  br: '4px',
  fontSize: 'md',
  itemTitle: 'title'
}

export default HorizontalRadioBtnGroup;
